const SmallLoader = () => {
  return (
    <div className="small-loader">
      <div className="loadingio-spinner-reload-sw831pn7az">
        <div className="ldio-h22nyabbwol">
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmallLoader



export enum actionsTypes {
  SET_PACKAGES = "assessmentTask/SET_PACKAGES",
  SET_LANGUAGES = "assessmentTask/SET_LANGUAGES",
  SET_IS_ONLINE = "assessmentTask/SET_IS_ONLINE",
  RESET_STATE = "assessmentTask/RESET_STATE",
  SET_INCLUDE_TEST_CASES = "assessmentTask/SET_INCLUDE_TEST_CASES",
  SET_NAME_QUESTION_FROM_TASK = "assessmentTask/SET_NAME_QUESTION_FROM_TASK",
  SET_QUESTION_DESCRIPTION = "assessmentTask/SET_QUESTION_DESCRIPTION",
  SET_CANDIDATE_INSTRUCTION = "assessmentTask/SET_CANDIDATE_INSTRUCTION",
  SET_STARTER_CODE = "assessmentTask/SET_STARTER_CODE",
  SET_QUESTIONS = "assessmentTask/SET_QUESTIONS",
  DELETE_TAKE_HOME_TASK = "assessmentTask/DELETE_TAKE_HOME_TASK",
  SET_DATABASE = "assessmentTask/SET_DATABASE",
  ADD_DATABASE = "assessmentTask/ADD_DATABASE",
  SET_ASSESSMENT_QUESTION = "assessmentTask/SET_ASSESSMENT_QUESTION",
  SET_FORM_QUESTIONS = "assessmentTask/SET_FORM_QUESTIONS",
  SET_ASSIGNMENT_NAME = "assessmentTask/SET_ASSIGNMENT_NAME",
  SET_ASSIGNMENT_DESCRIPTION = "assessmentTask/SET_ASSIGNMENT_DESCRIPTION",
  SET_TESTCASE_INPUTQUESTION = "assessmentTask/SET_TESTCASE_INPUTQUESTION",
  ADD_TAKEHOME_TASKINPUT = "assessmentTask/ADD_TAKEHOME_TASKINPUT",
  ADD_TAKE_HOME_TASK_TEST_CASE = "assessmentTask/ADD_TAKE_HOME_TASK_TEST_CASE",
  ADD_HOME_TASK_TEST_CASES = "assessmentTask/ADD_HOME_TASK_TEST_CASES",
  ADD_HOME_TASK_TEST_CASE_OUTPUT = "assessmentTask/ADD_HOME_TASK_TEST_CASE_OUTPUT",
  ADD_HOME_TASK_TEST_CASE_INPUTS = "assessmentTask/ADD_HOME_TASK_TEST_CASE_INPUTS",
  DELETE_TEST_CASE = "assessmentTask/DELETE_TEST_CASE",
  DELETE_TEST_CASE_IN_OUT = "assessmentTask/DELETE_TEST_CASE_IN_OUT",
  VALID_STATUS_BAR = "assessmentTask/VALID_STATUS_BAR",
  SET_FILTERED_DATA = "assessmentTask/SET_FILTERED_DATA",
  SET_HAS_FILTER ="assessmentTask/SET_HAS_FILTER"
}

export type Actions =
  | SetValidStatusBar
  | SetAssessmentName
  | SetAssessmentDescription
  | SetFormQuestions
  | SetPackages
  | SetLanguages
  | SetIsOnline
  | SetIncludeTestCases
  | SetNameQuestionFromTask
  | ResetAssessmentTaskState
  | SetQuestionDescription
  | SetCandidateInstruction
  | SetStarterCode
  | SetQuestions
  | DeleteTakeHomeTask
  | SetDatabase
  | AddDatabase
  | SetTestCaseInputQuestion
  | AddTakeHomeTaskInput
  | AddTakeHomeTaskTestCase
  | AddHomeTaskTestCases
  | AddHomeTaskTestCaseOutput
  | DeleteTestCaseInput
  | DeleteTestCaseInOut
  | SetFilteredData
  | SetAssessmentQuestion 
  | SetHasFilter;

export interface SetPackages {
  type: actionsTypes.SET_PACKAGES;
  payload: any;
}

export interface SetHasFilter {
  type: actionsTypes.SET_HAS_FILTER;
  payload: boolean;
}

export interface SetLanguages {
  type: actionsTypes.SET_LANGUAGES;
  payload: any;
}

export interface SetIsOnline {
  type: actionsTypes.SET_IS_ONLINE;
  payload: boolean;
}

export interface SetIncludeTestCases {
  type: actionsTypes.SET_INCLUDE_TEST_CASES;
  payload: boolean;
}

export interface SetNameQuestionFromTask {
  type: actionsTypes.SET_NAME_QUESTION_FROM_TASK;
  payload: any;
}

export interface SetQuestionDescription {
  type: actionsTypes.SET_QUESTION_DESCRIPTION;
  payload: any;
}

export interface SetCandidateInstruction {
  type: actionsTypes.SET_CANDIDATE_INSTRUCTION;
  payload: any;
}

export interface SetStarterCode {
  type: actionsTypes.SET_STARTER_CODE;
  payload: any;
}

export interface SetQuestions {
  type: actionsTypes.SET_QUESTIONS;
  payload: any;
}

export interface DeleteTakeHomeTask {
  type: actionsTypes.DELETE_TAKE_HOME_TASK;
  payload: any;
}

export interface SetAssessmentQuestion {
  type: actionsTypes.SET_ASSESSMENT_QUESTION;
  payload: any;
}

export interface SetDatabase {
  type: actionsTypes.SET_DATABASE;
  payload: any;
}

export interface AddDatabase {
  type: actionsTypes.ADD_DATABASE;
  payload: any;
}

export interface SetFormQuestions {
  type: actionsTypes.SET_FORM_QUESTIONS;
  payload: boolean;
}

export interface SetAssessmentName {
  type: actionsTypes.SET_ASSIGNMENT_NAME;
  payload: string;
}

export interface SetAssessmentDescription {
  type: actionsTypes.SET_ASSIGNMENT_DESCRIPTION;
  payload: string;
}

export interface SetTestCaseInputQuestion {
  type: actionsTypes.SET_TESTCASE_INPUTQUESTION;
  payload: any;
}

export interface AddTakeHomeTaskInput {
  type: actionsTypes.ADD_TAKEHOME_TASKINPUT;
  payload: any;
}

export interface AddTakeHomeTaskTestCase {
  type: actionsTypes.ADD_TAKE_HOME_TASK_TEST_CASE;
  payload: any;
}

export interface AddHomeTaskTestCases {
  type: actionsTypes.ADD_HOME_TASK_TEST_CASES;
  payload: any;
}

export interface AddHomeTaskTestCaseOutput {
  type: actionsTypes.ADD_HOME_TASK_TEST_CASE_OUTPUT;
  payload: any;
}

export interface AddHomeTaskTestCaseInputs {
  type: actionsTypes.ADD_HOME_TASK_TEST_CASE_INPUTS;
  payload: any;
}

export interface DeleteTestCaseInput {
  type: actionsTypes.DELETE_TEST_CASE;
  payload: any;
}

export interface DeleteTestCaseInOut {
  type: actionsTypes.DELETE_TEST_CASE_IN_OUT;
  payload: any;
}

export interface SetValidStatusBar {
  type: actionsTypes.VALID_STATUS_BAR;
  payload: any;
}

export interface SetFilteredData {
  type: actionsTypes.SET_FILTERED_DATA;
  payload: any;
}

export interface ResetAssessmentTaskState {
  type: actionsTypes.RESET_STATE;
}

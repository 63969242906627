import { liveTask as types } from "../../types";
import * as actions from "../../types/liveTask/actions.types";

export const setLanguage = (
  payload: types.State["language"]
): actions.SetLanguage => ({
  type: types.actionsTypes.SET_LANGUAGE,
  payload,
});

export const setPackage = (
  payload: types.State["packageLiveTask"]
): actions.SetPackage => ({
  type: types.actionsTypes.SET_PACKAGE,
  payload,
});

export const setName = (payload: types.State["name"]): actions.SetName => ({
  type: types.actionsTypes.SET_NAME,
  payload,
});

export const setIsOnline = (
  payload: types.State["isOnline"]
): actions.SetIsOnline => ({
  type: types.actionsTypes.SET_IS_ONLINE,
  payload,
});

export const setDescription = (
  payload: types.State["description"]
): actions.SetDescription => ({
  type: types.actionsTypes.SET_DESCRIPTION,
  payload,
});

export const setInstruction = (
  payload: types.State["instruction"]
): actions.SetInstruction => ({
  type: types.actionsTypes.SET_INSTRUCTION,
  payload,
});

export const setStarterCode = (
  payload: types.State["starterCode"]
): actions.SetStarterCode => ({
  type: types.actionsTypes.SET_STARTER_CODE,
  payload,
});

export const setNextStep = (
  payload: types.State["nextStep"]
): actions.SetNextStep => ({
  type: types.actionsTypes.SET_NEXT_STEP,
  payload,
});

export const SetValidStatusBar = (
  payload: types.State["validStatusBar"]
): actions.SetValidStatusBar => ({
  type: types.actionsTypes.VALID_STATUS_BAR,
  payload,
});

export const setDatabases = (
  payload: types.State["databases"]
): actions.SetDatabases => ({
  type: types.actionsTypes.SET_DATABASES,
  payload,
});

export const addDatabase = (
  payload: types.State["database"]
): actions.AddDatabase => ({
  type: types.actionsTypes.ADD_DATABASE,
  payload,
});

export const resetLiveTaskState = (): actions.ResetLiveTaskState => ({
  type: types.actionsTypes.RESET_STATE,
});

import { FC } from "react";

interface IProgressBar {
  homeQuestion?: any;
  isIncludeTestCases?: boolean;
  validStatusBar?: any;
  nextStep?: any;
  setCanBeCreated?: (value: boolean) => void;
}

const ProgressBar: FC<IProgressBar> = ({
  isIncludeTestCases,
  homeQuestion,
  validStatusBar,
  nextStep,
  setCanBeCreated,
}) => {
  const isLiveTask = window.location.pathname.includes("live-task");

  isLiveTask
    ? setCanBeCreated(nextStep)
    : setCanBeCreated(homeQuestion?.formStep === 3);

  return (
    <section className="bar-home-container">
      {isLiveTask || !isIncludeTestCases ? (
        <div className="details-content-bar">
          <ul className="timeline" id="timeline">
            <li
              className={`li pre-complete ${
                (homeQuestion?.validStatusBar?.stepOne === true ||
                  validStatusBar?.stepOne === true) &&
                "complete"
              }`}
            >
              <div className="status"></div>
            </li>
            <li
              className={`li ${
                (homeQuestion?.validStatusBar.stepThree === true ||
                  (validStatusBar?.stepTwo === true && nextStep === 1)) &&
                "complete-next"
              } ${
                (homeQuestion?.formStep === 3 || nextStep === 1) &&
                "pre-complete-next"
              }`}
            >
              <div className="status"></div>
            </li>
          </ul>
          <div className="text-test-timeline">
            <h4>Question Details</h4>
            <h4> Content of the question </h4>
          </div>
        </div>
      ) : (
        <div className="details-home-content-bar">
          <ul className="home-timeline" id="timeline">
            <li
              className={`li-home home-complete ${
                homeQuestion?.validStatusBar.stepOne === true &&
                "home-complete-three"
              }`}
            >
              <div className="status-home three"></div>
            </li>
            <li
              className={`li-home ${
                homeQuestion?.validStatusBar.stepTwo === true &&
                "home-complete-three"
              } ${homeQuestion?.formStep === 2 && "home-complete"}`}
            >
              <div className="status-home three-mid"></div>
            </li>
            <li
              className={`li-home ${
                homeQuestion?.validStatusBar.stepThree === true &&
                "home-complete-next-three"
              } ${homeQuestion?.formStep === 3 && "home-complete-next"}`}
            >
              <div className="status-home three"></div>
            </li>
          </ul>
          <div className="text-home-test-timeline">
            <div>
              <h4>Question Details </h4>
            </div>
            <div>
              <h4>Test Cases</h4>
            </div>
            <div>
              <h4>Content of the question</h4>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ProgressBar;

import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Select from "@mui/material/Select";
import DropdownFilter from "../filters/DropdownFilter";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { CheckboxButton } from "../CheckboxButton";
import SearchInput from "../SearchInput";
import { useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { useDispatch } from "react-redux";
import { getEnv } from "@urecruits/api";
import { setHasFilter } from '../../store/assessmentTask/assessmentTask.actions';
const settings = require("./../../images/settings.svg");
const burgerIcon = require("./../../images/burgerIcon.svg");
const {API_ASSESSMENT} = getEnv()
const names = [
  "Questions Type",
  "Languages",
  "Created on",
];

const status = ["Active", "Draft"];

const BootstrapInputActiveStatus = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    paddingBottom: "20px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    fontSize: 14,
    fontWeight: 900,
    background: "none",
    width: 133,
    padding: "9px 26px 5px 12px",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Avenir LT Std",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  "&.Mui-focused": {
    color: "#099C73",
    border: "2px solid #099C73",
    "& .MuiSvgIcon-root": {
      color: "#099C73",
    },
  },
  "&.MuiSvgIcon-root": {
    color: "#343B43",
    marginBottom: "5px",
  },
}));
function hasValue(nestedObj) {
  return Object.values(nestedObj).some(value => {
    return value !== '' && value !== null && value !== undefined &&
      (typeof value !== 'object' || Object.keys(value).length > 0);
  });
}

interface ITopFiled {
  searchValue: string;
  onSearchChange: () => void;
  setSearchValue: Dispatch<SetStateAction<string>>;
  tableNames: string[];
}

export const TopFiled: FC<ITopFiled> = ({
  searchValue,
  onSearchChange,
  setSearchValue,
  tableNames,
}) => {

  const {checkUserPermission} = useHasPermission()
  const dispatch = useDispatch()
  if(!checkUserPermission('assessment','view')){
    return <></>;
  }
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isCheck, setIsCheck] = useState(
    [...tableNames].map((itm, id) => id.toString())
  );
  const [assessmentQuery, setAssessmentQuery] = useState({section:{},status:''})
  const [isCheckStatus, setIsCheckStatus] = useState([]);

  const onOpenDropdown = (e) => {
    e.preventDefault();
    setIsOpenDropdown(!isOpenDropdown);
  };

  const onChangeTableColumn = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id.toString()]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const handleChangeStatus = (event) => {
    const { id, checked } = event.target;
    setIsCheckStatus([...isCheckStatus, id.toString()]);
    if (!checked) {
      setIsCheckStatus(isCheckStatus.filter((item) => item !== id));
    }
  };

  useEffect(()=>{
    if(isCheckStatus.length === 1){
      setAssessmentQuery(prev =>({...prev, status:isCheckStatus[0] === '0' ? 'ACTIVE' : isCheckStatus[0] === '1' ? 'DRAFT' : ''}))
    }else{
      setAssessmentQuery(prev=>({...prev,status:''}))
    }
  },[isCheckStatus])

  useEffect(()=>{
    if (hasValue(assessmentQuery)){
      setHasFilter(true)
    }else{
      setHasFilter(false)
    }
  },[assessmentQuery])

  const handleReset = (e) => {
    e.target.value = "";
  };

  return (
    <div className="top-field">
      <div className="top-field__container">
        {<DropdownFilter items={names} assessmentQuery={assessmentQuery} setAssessmentQuery={setAssessmentQuery} />}
        <SearchInput
          setSearchValue={setSearchValue}
          onSearchChange={onSearchChange}
          searchValue={searchValue}
        />
      </div>
      <FormControl
        sx={{ m: 1, width: 133 }}
        variant="standard"
        style={{ marginLeft: "15px" }}
      >
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          sx={{
            background: "none",
            height: "36px",
            borderRadius: "4px",
            color: "#343B43",
            border: "2px solid #343B43",
          }}
          id="demo-simple-select-standard"
          displayEmpty
          value={""}
          input={<BootstrapInputActiveStatus />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Select status</em>;
            }

            return selected;
          }}
        >
          <div className="top-field__status-color-container">
            {status.map((item, index) => (
              <MenuItem
                data-my-value={item}
                value={item}
                key={index}
                onChange={handleChangeStatus}
              >
                <div className="top-field__checkbox">
                  <CheckboxButton
                    className="top-field__checkbox__change"
                    type="checkbox"
                    name={item}
                    id={index}
                    isChecked={isCheckStatus.includes(index.toString())}
                  />
                </div>
                <div className="menu-item-status-dropdown">
                  <div className="top-field__title">{item}</div>
                </div>
              </MenuItem>
            ))}
          </div>
        </Select>
      </FormControl>
      {/* <div role="button" tabIndex={0} onClick={(e) => onOpenDropdown(e)}>
        <img
          className={`top-field__settings-icon ${
            !!isOpenDropdown && "background"
          }`}
          src={settings}
          alt="settings"
        />
      </div>
      {isOpenDropdown && (
        <div
          className="top-field__dropdown-colum-select"
          style={{
            position: "absolute",
            right: 0,
            top: "53px",
            background: "#fff",
            boxShadow: "0 0 9px 1px rgba(0, 0, 0, 0.25)",
            borderRadius: "4px",
            padding: "10px 0",
          }}
          onChange={onChangeTableColumn}
        >
          {tableNames.map((item, id) => (
            <MenuItem value={item} key={id}>
              <div className="top-field__menu-item">
                <div className="top-field__column-checkbox">
                  <CheckboxButton
                    className="top-field__column-checkbox-changes"
                    type="checkbox"
                    name={item}
                    id={id}
                    isChecked={isCheck.includes(id.toString())}
                  />
                </div>
                <span className="top-field__column-title">{item}</span>
              </div>
              <img
                className="top-field__column-burger"
                src={burgerIcon}
                alt="burger-icon"
              />
            </MenuItem>
          ))}
        </div>
      )} */}
    </div>
  );
};

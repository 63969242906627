export enum actionTypes {
  SET_FILTER_PACKAGE = "filter/SET_FILTER_PACKAGE",
  SET_FILTER_LANGUAGES = "filter/SET_FILTER_LANGUAGES",
  SET_IS_ONLINE = "filter/SET_IS_ONLINE",
  SET_FILTER_QUESTION_TYPE = "filter/SET_FILTER_QUESTION_TYPE",
  SET_FILTER_CREATED_BY = "filter/SET_FILTER_CREATED_BY",
  SET_FILTER_CREATED_ON = "filter/SET_FILTER_CREATED_ON",
  RESET_STATE = "filter/RESET_STATE",
}

export type Actions =
  | SetFilterLanguages
  | SetFilterPackage
  | SetFilterQuestionType
  | SetFilterCreatedBy
  | SetFilterCreatedOn
  | SetIsOnline;

export interface SetFilterLanguages {
  type: actionTypes.SET_FILTER_LANGUAGES;
  payload: any;
}

export interface SetFilterPackage {
  type: actionTypes.SET_FILTER_PACKAGE;
  payload: any;
}

export interface SetFilterQuestionType {
  type: actionTypes.SET_FILTER_QUESTION_TYPE;
  payload: any;
}

export interface SetFilterCreatedBy {
  type: actionTypes.SET_FILTER_CREATED_BY;
  payload: any;
}

export interface SetFilterCreatedOn {
  type: actionTypes.SET_FILTER_CREATED_ON;
  payload: any;
}

export interface SetIsOnline {
  type: actionTypes.SET_IS_ONLINE;
  payload: boolean;
}

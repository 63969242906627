import { Link, navigate } from "@reach/router";
import cn from "classnames";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateFormField } from "../../config/utils";
import fetchData from "../../hook/fetchData";
import postData from "../../hook/postData";
import { assessmentTaskSelectors } from "../../store/assessmentTask/assessmentTask.selectors";
import {
  SetValidStatusBar,
  resetLiveTaskState,
  setDescription,
  setLanguage,
  setName,
  setNextStep,
  setPackage
} from "../../store/liveTask/liveTask.actions";
import { liveTaskSelectors } from "../../store/liveTask/liveTask.selectors";
import CodingSelect from "../_elements/CodingSelect";
import DialogCreate from "./DialogCreate";
import DialogDraftQuestion from "./DialogDraftQuestion";
import LiveTaskNextStep from "./LiveTaskNextStep";
import ProgressBar from "./ProgressBar";
import { getEnv } from "@urecruits/api";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const arrowTask = require("../../images/arrowTask.svg");
const sendToDraft = require("../../images/sendToDraft.svg");
const {API_ASSESSMENT}=getEnv()

const backToGeneralArrow = require("../../images/backToGeneralArrow.svg");

const LiveTask = (props) => {
  const [useTask, setUseTask] = useState(false);
  const [validFields, setValidFields] = useState(false);
  const [validFieldsButtonNext, setValidFieldsButtonNext] = useState(false);
  const [validFieldsButtonCreate, setValidFieldsButtonCreate] = useState(false);
  const [validFieldsName, setValidFieldsName] = useState(false);
  const [validFieldsDescription, setValidFieldsDescription] = useState(false);
  const [canBeCreated, setCanBeCreated] = useState(false);
  const [dialogCreateDatabase, setDialogCreateDatabase] = useState(false);
  const [dialogDraftQuestion, setDialogDraftQuestion] = useState(false);
  const [showDraft, setShowDraft] = useState(false);
  const [databaseDetails, setDatabaseDetails] = useState<any>();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenWidth(window.innerWidth);
    };
    addEventListener("resize", updateScreenSize);
    return () => {
      removeEventListener("resize", updateScreenSize);
    };
  }, []);

  const { assessmentQuestion } = useSelector(
    assessmentTaskSelectors.getAllState
  );

  const {
    name,
    language,
    packageLiveTask,
    description,
    nextStep,
    validStatusBar,
    starterCode,
    instruction,
    database,
    databases,
  } = useSelector(liveTaskSelectors.getAllState);

  const [data, setData] = useState();
  const [languagesData, setLanguagesData] = useState();
  const [dialog, setDialog] = useState(false);
  const [packageOptions, setPackageOptions] = useState([]);
  const [databaseOptions, setDatabaseOptions] = useState([]);

  const dispatch = useDispatch();
  const html = draftToHtml(convertToRaw(instruction.getCurrentContent()));

  const handleNext = () => {
    navigate("/coding-assessments/live-task/next-step");
  };

  useEffect(() => {
    if (!languagesData) {
      fetchData(`${API_ASSESSMENT}/api/languages`, setLanguagesData)
        .then((res) => res.data)
        .then((res) => setLanguagesData(res));
    }
  }, [languagesData]);

  useEffect(() => {
    fetchData(
      `${API_ASSESSMENT}/api/live-coding/packages`,
      setPackageOptions
    ).then((res) => res.data);
  }, []);

  useEffect(() => {
    fetchData(
      `${API_ASSESSMENT}/api/assesment-database`,
      setDatabaseOptions
    ).then((res) => res.data);
  }, [databases,dialog]);

  const onHandleNameQuestion = (e) => {
    dispatch(setName(e.target.value));
    setValidFieldsName(e.target.value === "");
  };

  useEffect(() => {
    setValidFields(
      !(name === "" || language === "" || description === "") &&
        !(name === null || language === null || description === null)
    );
  }, [description, language, name]);

  useEffect(() => {
    dispatch(SetValidStatusBar({ stepOne: validFields }));
  }, [dispatch, validFields]);

  const requiredFieldByCreateButton = () =>
    validStatusBar.stepOne === true && validStatusBar.stepTwo === true;

  const onSave = () => {
    setValidFieldsButtonNext(true);
    setValidFieldsButtonCreate(true);
    if (requiredFieldByCreateButton() && validFields) {
      setDialog(true);
      postData(`${API_ASSESSMENT}/api/live-coding`, {
        name: name,
        description: description,
        languageId: language?.id,
        packageId: packageLiveTask ? packageLiveTask?.id : null,
        databaseId: databaseDetails ? databaseDetails.id : null,
        starterCode: starterCode,
        instruction: JSON.stringify(html),
        taskId:props.id || null
      }).then(
        (res) => {
          setData(res);
          setTimeout(() => {
            dispatch(resetLiveTaskState());
          }, 1500);
        },
        (err) => {
          console.error(err);
        }
      );
      dispatch(resetLiveTaskState());
    }
  };

  const onSendToDraft = () => {
    postData(`${API_ASSESSMENT}/api/live-coding/drafts`, {
      name: name,
      description: description,
      languageId: language?.id,
      packageId: packageLiveTask ? packageLiveTask?.id : null,
      databaseId: databaseDetails ? databaseDetails.id : null,
      starterCode: starterCode,
      instruction: JSON.stringify(html),
      taskId:props.id || null
    }).then(
      (res) => {
        setData(res);
        setTimeout(() => {
          dispatch(resetLiveTaskState());
        }, 1500);
        if(localStorage.getItem("prevRoute")==="/recruitment/position-workflow/create")
        { 
          localStorage.setItem("prevRoute","")
          navigate("/recruitment/position-workflow/create");
        }else{
          navigate("/coding-assessments");
        }
      },
      (err) => {
        console.error(err);
      }
    );
    dispatch(resetLiveTaskState());
  };

  const isEdit = () => {
    const includeId = window.location.pathname.split("/").slice(-1)[0];
    return "live-task" === includeId;
  };

  const onDialog = () => {
    if(localStorage.getItem("prevRoute")==="/recruitment/position-workflow/create")
    { 
      localStorage.setItem("prevRoute","")
      navigate("/recruitment/position-workflow/create");
    }else{
      navigate("/coding-assessments");
    }
  };

  const onCreateDatabase = () => {
    setDialogCreateDatabase(true);
  };

  useEffect(()=>{
    if(database?.id && databaseOptions.length>0){
      setDatabaseDetails(databaseOptions.find(i=>i.id === database.id))
    }
  },[databaseOptions,database])

  return (
    <AuthGuard module='assessment' permission={['add','edit']}>
    <section className="create-live-task">
      <div className="create-live-task__container">
        <div className="create-live-task__header">
          <div className="task__header">
            <h1>
              Create a New Coding Assessments{" "}
              <button
                className="task__header__select"
                onClick={() => setUseTask(!useTask)}
              >
                <h3 className="task__header-name">- LIVE TASK</h3>
                <img
                  alt="arrow-task"
                  className={`header__user__arrow ${useTask && "turn-arrow"}`}
                  src={arrowTask}
                />
              </button>
            </h1>

            {useTask && (
              <ul className="task">
                <li className="task__take">
                  <Link
                    className="task__item"
                    to="/coding-assessments/live-task"
                  >
                    <h3 className="task__name">LIVE TASK</h3>
                  </Link>
                  <Link
                    className="task__item"
                    to={
                      assessmentQuestion.id === ""
                        ? "/coding-assessments/take-home-task"
                        : "/coding-assessments/take-home-task/task-question"
                    }
                  >
                    <h3 className="task__name">TAKE HOME TASK</h3>
                  </Link>
                </li>
              </ul>
            )}
          </div>
          <div className="button-group">
            {screenWidth > 576 ? (
              <>
                <button
                  onClick={() => {
                    dispatch(resetLiveTaskState());
                    if (
                      localStorage.getItem("prevRoute") ===
                      "/recruitment/position-workflow/create"
                    ) {
                      localStorage.setItem("prevRoute", "");
                      navigate("/recruitment/position-workflow/create");
                    } else {
                      navigate("/coding-assessments");
                    }
                  }}
                  className="button-group__cancel-button-task"
                >
                  Cancel
                </button>
                <div className="button-group__edit-button-task">
                  <button
                    onClick={() => setDialogDraftQuestion(true)}
                    className="send-button-task"
                  >
                    <img
                      alt="send-task"
                      className="pen-icon"
                      src={sendToDraft}
                    />
                    Send to Draft
                  </button>
                </div>
                <button
                  disabled={!canBeCreated}
                  onClick={onSave}
                  className="btn btn--green"
                >
                  {isEdit() ? "Create" : "Save"}
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    dispatch(resetLiveTaskState());
                    if (
                      localStorage.getItem("prevRoute") ===
                      "/recruitment/position-workflow/create"
                    ) {
                      localStorage.setItem("prevRoute", "");
                      navigate("/recruitment/position-workflow/create");
                    } else {
                      navigate("/coding-assessments");
                    }                  }}
                  className="button-group__cancel-button-task"
                >
                  <img src={backToGeneralArrow} alt="cancel-arrow" />
                </button>
                <button
                  disabled={!canBeCreated}
                  onClick={onSave}
                  className="btn btn--green button-group__create"
                >
                  {isEdit() ? "Create" : "Save"}
                </button>
                <button
                  onClick={() => setShowDraft(!showDraft)}
                  className={cn("button-group__visibility-switcher", {
                    ["active"]: showDraft,
                  })}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_6557_59203)">
                      <path
                        d="M13 3V10H19L11 21V14H5L13 3Z"
                        stroke="#099C73"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6557_59203">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                {showDraft && (
                  <div className="button-group__edit-button-task">
                    <button
                      onClick={() => setDialogDraftQuestion(true)}
                      className="send-button-task"
                    >
                      <img
                        alt="send-task"
                        className="pen-icon"
                        src={sendToDraft}
                      />
                      Send to Draft
                    </button>
                  </div>
                )}
              </>
            )}

            {dialogDraftQuestion && (
              <DialogDraftQuestion
                onDialog={setDialogDraftQuestion}
                onSendToDraft={onSendToDraft}
              />
            )}
            {dialog && <DialogCreate onDialog={onDialog} />}
          </div>
        </div>
        {nextStep >= 0 && (
          <ProgressBar
            setCanBeCreated={setCanBeCreated}
            nextStep={nextStep}
            validStatusBar={validStatusBar}
          />
        )}
        {nextStep === 0 && (
          <div className="task-live">
            <form className="task-live__container" onSubmit={handleNext}>
              <h3 className="task-live__title">01. Assessment Details</h3>
              <label htmlFor="live-task-label" className="label-task-font">
                <span>
                  Name of Assessment <span className="asterisk-sign">*</span>
                </span>
              </label>
              <input
                className={`live-task-input ${
                  validateFormField(
                    name,
                    validFieldsName,
                    validFieldsButtonNext
                  ) && "error"
                }`}
                type="text"
                maxLength={120}
                value={name === "Untitled question" ? "" : name}
                placeholder="Enter question title"
                onChange={onHandleNameQuestion}
              />
              {validateFormField(
                name,
                validFieldsName,
                validFieldsButtonNext
              ) && <div className="error-data-one">Invalid data</div>}
              <div className="two-input-task">
                <div className="two-input-task-select-bar">
                  <label htmlFor="live-task-label" className="label-task-font">
                    <span>
                      Language <span className="asterisk-sign">*</span>
                    </span>
                  </label>
                  <CodingSelect
                    validate={validFieldsButtonNext && language === null}
                    placeholder="Select languages"
                    options={languagesData ? languagesData : []}
                    selected={language}
                    setSelected={(activeLanguage) =>
                      dispatch(setLanguage(activeLanguage))
                    }
                  />
                </div>
                <div className="two-input-task-select-bar">
                  <label htmlFor="live-task-label" className="label-task-font">
                    Packages
                  </label>
                  <CodingSelect
                    placeholder="Select packages"
                    options={packageOptions}
                    selected={packageOptions.find(pkg=>pkg.id === packageLiveTask?.id)}
                    setSelected={(activePackage) =>{
                      dispatch(setPackage(activePackage))}
                    }
                  />
                </div>
              </div>
              {/* <div className="input-button-database">
                <div className="two-input-task-select-bar input-database">
                  <label htmlFor="live-task-label" className="label-task-font">
                    <span>Database</span>
                  </label>
                  <CodingSelect
                    placeholder="Select database"
                    options={databaseOptions}
                    selected={databaseDetails}
                    setSelected={(activeDatabase) => {
                      setDatabaseDetails(
                        Object.assign({}, databaseDetails, activeDatabase)
                      );
                    }}
                  />
                </div>
                <div className="button-create-database-wrapper">
                  <button
                    onClick={onCreateDatabase}
                    type="button"
                    className="button-create-database"
                  >
                    Create database
                  </button>
                  {dialogCreateDatabase && (
                    <DialogLiveDatabase
                      database={database}
                      onDialog={setDialogCreateDatabase}
                    />
                  )}
                </div>
              </div> */}
              <label htmlFor="live-task-label" className="label-task-font">
                Assessment Description <span className="asterisk-sign">*</span>
              </label>
              <textarea
                rows={4}
                value={description}
                className={`live-task-input-textarea ${
                  validateFormField(
                    description,
                    validFieldsDescription,
                    validFieldsButtonNext
                  ) && "error"
                }`}
                placeholder="Write question description"
                onChange={(e) => {
                  dispatch(setDescription(e.target.value));
                  setValidFieldsDescription(e.target.value === "");
                }}
              />
              {validateFormField(
                description,
                validFieldsDescription,
                validFieldsButtonNext
              ) && <div className="error-data-one">Invalid data</div>}
              <div
                style={{
                  fontSize: "12px",
                  marginTop: "20px",
                  color: "#999EA5",
                }}
              >
                This description will be visible only in list of coding
                questions
              </div>
              <div className="first-group-button-task">
                <div>
                  <button
                    onClick={(e) => {
                      dispatch(setNextStep(nextStep + 1));
                      setValidFieldsButtonNext(true);
                      e.preventDefault();
                    }}
                    type="submit"
                    className="button-save"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        {nextStep === 1 && (
          <div>
            <LiveTaskNextStep
              validFieldsButtonCreate={validFieldsButtonCreate}
              props={props}
            />
          </div>
        )}
      </div>
    </section>
    </AuthGuard>
  );
};

export default LiveTask;

import { assessmentTask } from "../../types";
import { RootState } from "../root-reducer";

const getAllState = (state: RootState): assessmentTask.State => {
  return state.assessmentTask;
};

export const assessmentTaskSelectors = {
  getAllState,
};

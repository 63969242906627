import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { CheckboxButton } from "../CheckboxButton";
import cn from "classnames";
import { navigate } from "@reach/router";
import TableRow from "@mui/material/TableRow";
import useWindowSize from "../../hook/common/useWindowSize";
import { useDispatch } from "react-redux";
import {
  setDescription,
  setInstruction,
  setLanguage,
  setName,
  setPackage,
  setStarterCode,
} from "../../store/liveTask/liveTask.actions";
import {
  setAssessmentDescription,
  setAssessmentName,
} from "../../store/assessmentTask/assessmentTask.actions";
import { EditorState } from "draft-js";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const pencil = require("./../../images/pencil.svg");
const remove = require("./../../images/delete.svg");
const showCellIco = require("./../../images/showCellIco.svg");
const hideCellIco = require("./../../images/hideCellIco.svg");

const tableFields = [
  { label: "name", name: "Name of the question" },
  { label: "description", name: "Description" },
  { label: "type", name: "Question Type" },
  { label: "languageId", name: "Languages" },
  { label: "packageId", name: "Packages" },
  { label: "updatedOn", name: "Last Updated on" },
  { label: "status", name: "Status" },
  { label: "actions", name: "Actions" },
];

const MobileTableRows = (props) => {
  const {
    rawData,
    info,
    isOddNumber,
    handleRemove,
    keyRow,
    handleClick,
    isCheck,
    id,
    isCheckAll,
    index,
    cellStyles = [
      {
        color: "#099C73",
        fontWeight: 900,
        position: "sticky",
        left: 0,
        width: "280px",
      },
      { color: "#464E57", fontWeight: 400, width: "200px" },
      { color: "#343B43", fontWeight: 400, width: "200px" },
      { color: "#343B43", fontWeight: 400, width: "200px" },
      { color: "#343B43", fontWeight: 400, width: "200px" },
      { color: "#099C73", fontWeight: 500, width: "200px" },
      { color: "#737980", fontWeight: 400, width: "200px" },
    ],
  } = props;

  const [widthRowsAssessment, setWidthRowsAssessment] = useState(0);
  const [showCell, setShowCell] = useState(false);


  const rowsRef = useRef(null);
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    const { width } = rowsRef?.current?.getBoundingClientRect();
    setWidthRowsAssessment(width);
  }, [widthRowsAssessment]);

  const [width] = useWindowSize();

  const onEditRow = (e, idx, row) => {
    return rawData.find((item) => {
      if (item.taskId === row.taskId && item.assessmentType === "take-home") {
        navigate("/coding-assessments/take-home-task/" + id);
        dispatch(setAssessmentName(item?.name));
        dispatch(setAssessmentDescription(item?.description));
      } else if (
        item.taskId === row.taskId &&
        item.assessmentType === "live-task"
      ) {
        navigate("/coding-assessments/live-task/" + idx);
        dispatch(setName(item?.name));
        dispatch(setDescription(item?.description));
        dispatch(setLanguage({ id: item?.languageId, name: row?.languageId }));
        dispatch(setPackage({ id: item?.packageId, name: row?.packageId }));
        dispatch(
          setInstruction(
            item?.instruction ? item?.instruction : EditorState.createEmpty()
          )
        );
        dispatch(setStarterCode(item?.starterCode));
      }
    });
  };

  const newWidth = useMemo(() => {
    return widthRowsAssessment > width - 122;
  }, [width, widthRowsAssessment]);

  return (
    <TableRow
      ref={rowsRef}
      style={{ padding: "0 10px 0 10px", borderBottom: "1px solid #DFE2E6" }}
      className={`table-ui__row ${isOddNumber ? "table-ui__row--white" : "table-ui__row--gray"
        }`}
    >
      {Object.entries(info).map((name: [string, any], index) => {
        const idx = name[0] === "taskId" ? name[1] : `${name[0]}-${index}`;
        const infoKeys = Object.keys(info);
        const indexName = infoKeys.indexOf("name") - 1;
        return (
          <div className="table-ui__row-m-content" key={info.taskId}>
            {name[0].includes("name") && !name[0].includes("id") && !name[0].includes("taskId") && (
              <div
                style={{
                  position: cellStyles[0]?.position,
                  left: cellStyles[0]?.left,
                  minWidth: cellStyles[0]?.width,
                  padding: "11px 0 0 16px",
                  zIndex: cellStyles[0]?.zIndex,
                }}
                className={cn(
                  "table-ui__row--text",
                  {
                    "table-ui__row--green-text": index === 0 || index === 5,
                  },
                  { "table-ui__row--white": isOddNumber === true },
                  { "table-ui__row--gray": isOddNumber === false }
                )}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AuthGuard module={'assessment'} permission={'delete'}>
                    <div style={{ margin: "-14px 12px -8px -22px" }}>
                      <CheckboxButton
                        key={info.taskId}
                        type="checkbox"
                        name={info.name}
                        id={info.taskId}
                        isCheckAll={isCheckAll}
                        handleClick={(e) => handleClick(e, info.taskId)}
                        isChecked={isCheck.includes(info.taskId.toString())}
                      />
                    </div>
                  </AuthGuard>
                  <div className="table-ui__m-row">
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "250px",
                        color: cellStyles[0]?.color,
                        fontWeight: cellStyles[0]?.fontWeight,
                      }}
                    >
                      {name[1] ? name[1] : "N/A"}
                    </span>
                    <button onClick={() => setShowCell(!showCell)}>
                      {showCell ? (
                        <img src={hideCellIco} alt="hide cell" />
                      ) : (
                        <img src={showCellIco} alt="show cell" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {index !== 2 &&
              showCell &&
              !name[0].includes("id") &&
              !name[0].includes("taskId") && (
                <div key={name["taskId"]}>
                  <div className="table-ui__row-tabindex">
                    {tableFields.map((itm) =>
                      itm.label === name[0] ? itm.name : ""
                    )}
                  </div>
                  {name[0].includes("status") ? (
                    <div
                      className={cn("table-ui__row-status-container", {
                        "table-ui__row-status-active": name[1].includes("ACTIVE"),
                        "table-ui__row-status-draft": name[1].includes("DRAFT"),
                      })}
                    >
                      {name[1] || name[1] === "" || name[1] === undefined ? (
                        name[1]
                      ) : (
                        <span> </span>
                      )}
                      {Array.isArray(name[1]) ? name[1].join(" | ") : <span> </span>}
                    </div>
                  ) : (
                    <div
                      className="table-ui__row-title"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "250px",
                        color: cellStyles[index]?.color,
                        fontWeight: cellStyles[index]?.fontWeight,
                      }}
                    >
                      {tableFields[index]?.label === name[0] ? name[1] : name[1]}
                    </div>
                  )}
                </div>
              )}
          </div>
        );
      })}
      {showCell && (
        <div className="table-ui__icon-m-wrapper">
          <AuthGuard module={'assessment'} permission={'edit'}>
              <button
                onClick={(e) => onEditRow(e, index, keyRow)}
                className="table-ui__row-m-icon-pen tab-btn tab-btn--white"
              >
                <img
                  style={{ width: "20px", marginRight: "10px" }}
                  src={pencil}
                  alt="pencil-icon"
                />
                <span>Edit Fields</span>
              </button>
          </AuthGuard>
          <AuthGuard module={'assessment'} permission={'delete'}>
              <button
                onClick={() => handleRemove(keyRow.taskId)}
                className="table-ui__row-icon-delete tab-btn tab-btn--white"
              >
                <img style={{ width: "20px", marginRight: "10px" }} src={remove} alt="remove" />
                <span>Delete</span>
              </button>
          </AuthGuard>
        </div>
      )}
    </TableRow>
  );
};

export default React.memo(MobileTableRows);

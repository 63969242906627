import { assessmentTask as types } from "../../types";
import * as actions from "../../types/assessmentTask/actions.types";

export const setIsOnline = (
  payload: types.State["isOnline"]
): actions.SetIsOnline => ({
  type: types.actionsTypes.SET_IS_ONLINE,
  payload,
});

export const setHasFilter = (
  payload: types.State["hasFilter"]
): actions.SetHasFilter => ({
  type: types.actionsTypes.SET_HAS_FILTER,
  payload,
});

export const setAssessmentQuestion = (
  payload: types.State["assessmentQuestion"]
): actions.SetAssessmentQuestion => ({
  type: types.actionsTypes.SET_ASSESSMENT_QUESTION,
  payload,
});

export const setQuestions = (
  payload: types.State["questions"]
): actions.SetQuestions => ({
  type: types.actionsTypes.SET_QUESTIONS,
  payload,
});

export const setFormQuestions = (
  payload: types.State["formQuestions"]
): actions.SetFormQuestions => ({
  type: types.actionsTypes.SET_FORM_QUESTIONS,
  payload,
});

export const DeleteTakeHomeTask = (
  payload: types.State["deleteTakeHomeTask"]
): actions.DeleteTakeHomeTask => ({
  type: types.actionsTypes.DELETE_TAKE_HOME_TASK,
  payload,
});

export const setAssessmentName = (
  payload: types.State["assessmentName"]
): actions.SetAssessmentName => ({
  type: types.actionsTypes.SET_ASSIGNMENT_NAME,
  payload,
});

export const setAssessmentDescription = (
  payload: types.State["assessmentDescription"]
): actions.SetAssessmentDescription => ({
  type: types.actionsTypes.SET_ASSIGNMENT_DESCRIPTION,
  payload,
});

export const setTestCaseInputQuestion = (
  payload: types.State["testCaseInputQuestion"]
): actions.SetTestCaseInputQuestion => ({
  type: types.actionsTypes.SET_TESTCASE_INPUTQUESTION,
  payload,
});

export const addTakeHomeTaskInputCase = (
  payload: types.State["addTakeHomeTaskInput"]
): actions.AddTakeHomeTaskInput => ({
  type: types.actionsTypes.ADD_TAKEHOME_TASKINPUT,
  payload,
});

export const addHomeTaskTestCases = (
  payload: types.State["testCases"]
): actions.AddHomeTaskTestCases => ({
  type: types.actionsTypes.ADD_HOME_TASK_TEST_CASES,
  payload,
});

export const addHomeTaskTestCaseInputs = (
  payload: types.State["testCaseInputs"]
): actions.AddHomeTaskTestCaseInputs => ({
  type: types.actionsTypes.ADD_HOME_TASK_TEST_CASE_INPUTS,
  payload,
});

export const addHomeTaskTestCaseOutput = (
  payload: types.State["testCaseOutputName"]
): actions.AddHomeTaskTestCaseOutput => ({
  type: types.actionsTypes.ADD_HOME_TASK_TEST_CASE_OUTPUT,
  payload,
});

export const deleteTestCaseInputOutput = (
  payload: types.State["deleteTestCaseInOut"]
): actions.DeleteTestCaseInOut => ({
  type: types.actionsTypes.DELETE_TEST_CASE_IN_OUT,
  payload,
});

export const deleteTestCaseInput = (
  payload: types.State["deleteTestCase"]
): actions.DeleteTestCaseInput => ({
  type: types.actionsTypes.DELETE_TEST_CASE,
  payload,
});

export const setValidStatusBar = (
  payload: types.State["validStatusBar"]
): actions.SetValidStatusBar => ({
  type: types.actionsTypes.VALID_STATUS_BAR,
  payload,
});

export const setDatabase = (
  payload: types.State["database"]
): actions.SetDatabase => ({
  type: types.actionsTypes.SET_DATABASE,
  payload,
});

export const addDatabase = (
  payload: types.State["databases"]
): actions.AddDatabase => ({
  type: types.actionsTypes.ADD_DATABASE,
  payload,
});

export const setFilteredData = (
  payload: types.State["filteredData"]
): actions.SetFilteredData => ({
  type: types.actionsTypes.SET_FILTERED_DATA,
  payload,
});


export const resetAssessmentTaskState =
  (): actions.ResetAssessmentTaskState => ({
    type: types.actionsTypes.RESET_STATE,
  });

import { useLayoutEffect, useRef, useState } from "react";
import { CheckboxButton } from "../CheckboxButton";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import useWindowSize from "../../hook/common/useWindowSize";
import cn from "classnames";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const arrow = require("../../images/icon/arrow.svg");

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  ".css-1bas054-MuiTableRow-root": {
    fontFamily: [
      "Avenir",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontWeight: 900,
  },
}));

export const Header = ({
  tableFields,
  checkedChange,
  handleSelectAll,
  isChecked,
  setSortingBy,
  sortTableData,
  setCurrentItems,
  isMouseOver,
  isMouseOverScroll,
}) => {

  const {checkUserPermission} = useHasPermission()
  const [sortingField, setSortingField] = useState<string>("");
  const [sortingOrder, setSortingOrder] = useState<string>("asc");

  const onSortingChange = (name) => {
    const order =
      name === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    setSortingField(name);
    setSortingOrder(order);
    setSortingBy({ name, order });
    setCurrentItems(sortTableData);
  };

  const [widthNameAssessment, setWidthNameAssessment] = useState(0);
  const nameRef = useRef(null);

  useLayoutEffect(() => {
    const { width } = nameRef?.current?.getBoundingClientRect();
    setWidthNameAssessment(width);
  }, [widthNameAssessment]);

  const [width] = useWindowSize();

  return (
    <TableHead ref={nameRef} className="table-ui__header">
      <StyledTableRow>
        {Object.values(tableFields).map((item: Record<string, any>, index) => {
          if (index === 0) {
            return (
              <TableCell
                key={index}
                className={cn("table-ui__header-name", {
                  "table-ui__header-shadow":
                    isMouseOver || isMouseOverScroll
                      ? widthNameAssessment > width - 122
                      : false,
                })}
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  verticalAlign: "middle",
                  maxWidth: "280px",
                  zIndex: 1,
                  background: "#fff",
                  left: 0,
                  fontFamily: "Avenir LT Std",
                  fontWeight: 900,
                  padding: "0 0 0 15px",
                  position: "sticky",
                  cursor: "auto",
                }}
              >
                <div
                  style={{
                    height: "56px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AuthGuard module={'assessment'} permission={'delete'}>
                    <div style={{ marginRight: "40px" }}>
                      <CheckboxButton
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        checkedChange={checkedChange}
                        handleClick={handleSelectAll}
                        isChecked={isChecked}
                      />
                    </div>
                  </AuthGuard>
                  <button onClick={() => onSortingChange(item.label)}>
                    <span>{item.name}</span>
                    &nbsp;&nbsp;&nbsp;
                    <img
                      src={arrow}
                      alt="arrow"
                      className="table-ui__header-name__img"
                    />
                  </button>
                </div>
              </TableCell>
            );
          } else {
            if(item.name === 'Actions' && !checkUserPermission('assessment',['edit','delete'])){
              return;
            }
            return (
              <TableCell
                key={index}
                className="table-ui__header-name"
                style={{
                  fontSize: "14px",
                  paddingLeft: "15px",
                  verticalAlign: "middle",
                  padding: "16px 0 16px 16px",
                  fontFamily: "Avenir LT Std",
                  fontWeight: 900,
                  position: "relative",
                  cursor: "auto",
                }}
              >
                {item.name.toLowerCase() === "actions" ? (
                  <div>{item.name}</div>
                ) : (
                  <button onClick={() => onSortingChange(item.label)}>
                    {item.name}
                    &nbsp;&nbsp;&nbsp;
                    <img
                      src={arrow}
                      alt="arrow"
                      className="table-ui__header-name__img"
                    />
                  </button>
                )}
              </TableCell>
            );
          }
        })}
      </StyledTableRow>
    </TableHead>
  );
};

import React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const TabQuestionType = ({ onChangeTypeTask, checkedTaskType }) => {
  return (
    <>
      <div onChange={onChangeTypeTask}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue=""
          name="radio-buttons-group"
          className="input-live-task"
          row
        >
          <FormControlLabel
            value="live-task"
            checked={checkedTaskType === "live-task"}
            control={
              <Radio
                sx={{
                  color: "#099C73",
                  "&.Mui-checked": {
                    color: "#099C73",
                  },
                }}
              />
            }
            label="Live Task"
          />
          <FormControlLabel
            value="take-home"
            checked={checkedTaskType === "take-home"}
            control={
              <Radio
                sx={{
                  marginLeft: "20px",
                  color: "#099C73",
                  "&.Mui-checked": {
                    color: "#099C73",
                  },
                }}
              />
            }
            label="Take Home"
          />
        </RadioGroup>
      </div>
    </>
  );
};

export default React.memo(TabQuestionType);

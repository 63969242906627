import React, { useState } from "react";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import draftToHtml from "draftjs-to-html";
import { useDispatch, useSelector } from "react-redux";
import { setAssessmentQuestion } from "../../store/assessmentTask/assessmentTask.actions";
import { liveTaskSelectors } from "../../store/liveTask/liveTask.selectors";
import { setInstruction } from "../../store/liveTask/liveTask.actions";

const ReachEditor = (props) => {
  const { homeQuestion, validate } = props;

  const { instruction } = useSelector(liveTaskSelectors.getAllState);

  const [editorState, setEditorState] = useState(
    homeQuestion ? homeQuestion.candidateInstruction : instruction
  );
  const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  const dispatch = useDispatch();
  const onChange = (editorStateChange) => {
    setEditorState(editorStateChange);
    if (homeQuestion) {
      dispatch(
        setAssessmentQuestion({
          ...homeQuestion,
          candidateInstruction: editorState,
        })
      );
    } else {
      dispatch(setInstruction(editorState));
    }
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onChange}
        wrapperClassName={`wrapper-class ${validate && "error"}`}
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: ["history", "inline", "colorPicker", "textAlign", "list"],
          inline: { inDropdown: true },
          textAlign: { inDropdown: true },
        }}
      />
      {validate && (
        <div className="error-data-one">Invalid data</div>
      )}
    </div>
  );
};

export default ReachEditor;

import { filter as types } from "../../types";
import produce from "immer";

export const initialValue: types.State = {
  filterLanguages: null,
  filterPackage: null,
  filterQuestionType: null,
  filterCreatedBy: null,
  filterCreatedOn: null,
  isOnline: true,
};

const reducer = (state = initialValue, action) => {
  switch (action.type) {
    case types.actionTypes.SET_FILTER_LANGUAGES:
      return produce(state, (draft) => {
        draft.filterLanguages = action.payload;
      });

    case types.actionTypes.SET_FILTER_PACKAGE:
      return produce(state, (draft) => {
        draft.filterPackage = action.payload;
      });

    case types.actionTypes.SET_FILTER_QUESTION_TYPE:
      return produce(state, (draft) => {
        draft.filterQuestionType = action.payload;
      });

    case types.actionTypes.SET_FILTER_CREATED_BY:
      return produce(state, (draft) => {
        draft.filterCreatedBy = action.payload;
      });

    case types.actionTypes.SET_FILTER_CREATED_ON:
      return produce(state, (draft) => {
        draft.filterCreatedOn = action.payload;
      });

    case types.actionTypes.SET_IS_ONLINE:
      return produce(state, (draft) => {
        draft.isOnline = action.payload;
      });

    case types.actionTypes.RESET_STATE:
      return { ...initialValue };

    default:
      return state;
  }
};

export default reducer;

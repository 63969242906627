import { liveTask as types } from "../../types";
import produce from "immer";
import { EditorState } from "draft-js";

export const initialValue: types.State = {
  name: null,
  description: null,
  language: null,
  packageLiveTask: null,
  instruction: EditorState.createEmpty(),
  starterCode: null,
  isOnline: true,
  nextStep: 0,
  validStatusBar: {},
  databases: [
    {
      questionId: "",
      id: 0,
      title: "",
      packages: null,
      description: "",
      script: "",
    },
  ],
  database: {
    questionId: "",
    id: 0,
    title: "",
    packages: null,
    description: "",
    script: "",
  },
};

const reducer = (state = initialValue, action: any) => {
  switch (action.type) {
    case types.actionsTypes.SET_LANGUAGE:
      return produce(state, (draft) => {
        draft.language = action.payload;
      });

    case types.actionsTypes.SET_PACKAGE:
      return produce(state, (draft) => {
        draft.packageLiveTask = action.payload;
      });

    case types.actionsTypes.SET_IS_ONLINE:
      return produce(state, (draft) => {
        draft.isOnline = action.payload;
      });

    case types.actionsTypes.SET_INSTRUCTION:
      return produce(state, (draft) => {
        draft.instruction = action.payload;
      });

    case types.actionsTypes.SET_STARTER_CODE:
      return produce(state, (draft) => {
        draft.starterCode = action.payload;
      });

    case types.actionsTypes.SET_NAME:
      return produce(state, (draft) => {
        draft.name = action.payload;
      });

    case types.actionsTypes.SET_DESCRIPTION:
      return produce(state, (draft) => {
        draft.description = action.payload;
      });

    case types.actionsTypes.SET_NEXT_STEP:
      return produce(state, (draft) => {
        draft.nextStep = action.payload;
      });

    case types.actionsTypes.VALID_STATUS_BAR:
      return produce(state, (draft) => {
        draft.validStatusBar = action.payload;
      });

    case types.actionsTypes.ADD_DATABASE:
      return produce(state, (draft) => {
        draft.database.id = action.payload.id;
        draft.database.title = action.payload.title;
        draft.database.packages = action.payload.packages;
        draft.database.description = action.payload.description;
        draft.database.script = action.payload.script;
      });

    case types.actionsTypes.SET_DATABASES:
      return produce(state, (draft) => {
        draft.databases = [
          {
            ...draft.database,
            ...action.payload,
            id: Math.floor(Math.random() * 1000000),
          },
        ];
      });

    case types.actionsTypes.RESET_STATE:
      return { ...initialValue };

    default:
      return state;
  }
};

export default reducer;

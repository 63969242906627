import React from "react";

export const CheckboxButton = (props) => {
  const { checkedChange, id, type, name, handleClick, isChecked, isCheckAll } =
    props;
  let xo = checkedChange ? !isChecked : isChecked;
  // let xoAll = checkedChange ? !isCheckAll : isCheckAll;
  return (
    <label className="container">
      <input
        //   className={isChecked ? "checkmark2" : ""}
        style={{ opacity: "0" }}
        id={id}
        name={name}
        type={type}
        checked={xo || false}
        onChange={handleClick}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export enum actionsTypes {
  SET_PACKAGE = "liveTask/SET_PACKAGES",
  SET_LANGUAGE = "liveTask/SET_LANGUAGES",
  SET_IS_ONLINE = "liveTask/SET_IS_ONLINE",
  RESET_STATE = "liveTask/RESET_STATE",
  SET_NAME = "liveTask/SET_NAME",
  SET_INSTRUCTION = "liveTask/SET_INSTRUCTION",
  SET_DESCRIPTION = "liveTask/SET_STARTER_CODE",
  SET_STARTER_CODE = "liveTask/SET_DESCRIPTION",
  SET_NEXT_STEP = "liveTask/SET_NEXT_STEP",
  VALID_STATUS_BAR = "liveTask/VALID_STATUS_BAR",
  SET_DATABASES = "liveTask/SET_DATABASES",
  ADD_DATABASE = "liveTask/ADD_DATABASE",
}

export type Actions =
  | SetName
  | SetPackage
  | SetLanguage
  | SetIsOnline
  | SetDescription
  | SetInstruction
  | SetNextStep
  | SetValidStatusBar
  | SetDatabases
  | AddDatabase
  | SetStarterCode;

export interface SetName {
  type: actionsTypes.SET_NAME;
  payload: any;
}

export interface SetPackage {
  type: actionsTypes.SET_PACKAGE;
  payload: any;
}

export interface SetLanguage {
  type: actionsTypes.SET_LANGUAGE;
  payload: any;
}

export interface SetIsOnline {
  type: actionsTypes.SET_IS_ONLINE;
  payload: boolean;
}

export interface SetDescription {
  type: actionsTypes.SET_DESCRIPTION;
  payload: any;
}

export interface SetInstruction {
  type: actionsTypes.SET_INSTRUCTION;
  payload: any;
}

export interface SetStarterCode {
  type: actionsTypes.SET_STARTER_CODE;
  payload: any;
}

export interface SetNextStep {
  type: actionsTypes.SET_NEXT_STEP;
  payload: any;
}

export interface SetValidStatusBar {
  type: actionsTypes.VALID_STATUS_BAR;
  payload: any;
}

export interface SetDatabases {
  type: actionsTypes.SET_DATABASES;
  payload: any;
}

export interface AddDatabase {
  type: actionsTypes.ADD_DATABASE;
  payload: any;
}

export interface ResetLiveTaskState {
  type: actionsTypes.RESET_STATE;
}

import React, { Fragment } from "react";
import cn from "classnames";

const SearchPortal = (props) => {
  return (
    <div className={cn({ "modal-portal": props.showModal })}>
      {props.children}
    </div>
  );
};

export default SearchPortal;

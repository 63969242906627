import React, { useEffect, useState } from "react";
import { Portal } from "../hook/portal";
import SearchPortal from "../hook/SearchPortal";

const cross = require("../images/icon/cross.svg");
const resetButton = require("../images/resetButton.svg");
const searchLogo = require("../images/search.svg");

const SearchInput = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenWidth(window.innerWidth);
    };
    addEventListener("resize", updateScreenSize);
    return () => {
      removeEventListener("resize", updateScreenSize);
    };
  }, []);

  const handleReset = (e) => {
    e.target.value = "";
  };

  return (
    <div className="search-input">
      <button
        onClick={() => setShowModal(true)}
        className="search-input__search-button btn--green"
      >
        <img
          src={searchLogo}
          alt="search-icon"
          className="search-input__icon"
        />
      </button>
      {screenWidth > 576 && (
        <input
          type="search"
          placeholder="Search coding questions"
          className="search-input__input"
          onChange={(e) => props.setSearchValue(e.target.value)}
          value={props.searchValue}
          onKeyDown={(e) => e.key === "Enter" && props.onSearchChange()}
        />
      )}
      {screenWidth < 576 && showModal && (
        <SearchPortal showModal={showModal}>
          <div key="Input1" className="modal">
            <div className="modal__container">
              <div className="modal__header-container">
                <div className="modal__filter-title">Search</div>
                <div>
                  <button
                    className="modal__cross-ico"
                    onClick={() => setShowModal(false)}
                  >
                    <img src={cross} alt="cross-icon" />
                  </button>
                </div>
              </div>
              <button
                onClick={() => {
                  props.onSearchChange();
                  setShowModal(false);
                }}
                className="search-input__search-button-modal btn--green"
              >
                <img
                  src={searchLogo}
                  alt="search-icon"
                  className="search-input__icon"
                />
              </button>
              <input
                type="search"
                placeholder="Search coding questions"
                className="search-input__input"
                onChange={(e) => props.setSearchValue(e.target.value)}
                value={props.searchValue}
                onKeyDown={(e) => e.key === "Enter" && props.onSearchChange()}
              />
            </div>
            <div className="modal__footer-container">
              <div className="modal__footer-line">
                <button onClick={handleReset}>
                  <img
                    style={{ width: "16px", marginBottom: "3px" }}
                    src={resetButton}
                    alt="reset"
                  />
                  &nbsp;&nbsp;
                  <span style={{ color: "#099C73" }}>Reset search</span>
                </button>
                <button
                  onClick={() => {
                    props.onSearchChange();
                    setShowModal(false);
                  }}
                  className="btn btn--green"
                >
                  Show result
                </button>
              </div>
            </div>
          </div>
        </SearchPortal>
      )}
    </div>
  );
};

export default React.memo(SearchInput);

import PaginationBoxView from "./table/pagination/PaginationBoxView";
import TableCell from "@mui/material/TableCell";
const itemPrevLabel = require("../images/itemPrevLabel.svg");
const itemNextLabel = require("../images/itemNextLabel.svg");

export const Pagination = ({ pageCount, handlePageClick }) => {
  const onClick = (e) => {
    handlePageClick(e);
  };

  return (
    <TableCell className="table-ui__pagination-wrapper-content">
      {
        <PaginationBoxView
          breakLabel="..."
          disabledClassName="table-ui__pagination-item-disabled"
          nextLabel={<img alt="next-label" src={itemNextLabel} />}
          previousLabel={<img alt="prev-label" src={itemPrevLabel} />}
          onPageChange={(e) => onClick(e)}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          // renderOnZeroPageCount={null}
          // forcePage={pageCount}
          containerClassName="table-ui__pagination"
          // breakClassName="table-ui__pagination-item"
          pageClassName="table-ui__pagination-item"
          activeClassName="table-ui__pagination-item--active"
          nextClassName="table-ui__pagination-item--next"
          previousClassName="table-ui__pagination-item--previous"
          pageLinkClassName="table-ui__pagination-link"
        />
      }
    </TableCell>
  );
};

const dialogCreate = require("../../images/dialogCreate.svg");

function DialogCreate({ onDialog }) {
  return (
    <div className="coding-dialog" onClick={() => onDialog(false)}>
      <div
        className="coding-dialog__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="coding-dialog__head">
          <h2 className="coding-dialog__head__title">Created</h2>
          <button
            className="coding-dialog__head__cross-ico"
            onClick={() => {
              onDialog(true);
              onDialog(false);
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                stroke="#C1C5CB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <img
          className="coding-dialog__center-img"
          src={dialogCreate}
          alt="cancel-icon"
        />
        <h3 className="coding-dialog__message">
          The coding question <span style={{fontWeight: 500}}>Take-Home with Test Cases</span> have been successfully
          created on{" "}
          <span style={{ color: "#099C73", fontWeight: 700 }}>uRecruits</span>. Click{" "}
          <a href={"/coding-assessments"} style={{ color: "#099C73" }}>
            {" "}
            here
          </a>{" "}
          to see it.
        </h3>
        <div style={{ marginLeft: "unset" }} className="coding-dialog__footer">
          <div
            className="coding-dialog__footer__container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <button
              className="coding-dialog__footer__container__delete"
              onClick={() => onDialog(true)}
            >
              Got it!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DialogCreate;

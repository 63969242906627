import { useState } from "react";
import { Link } from "@reach/router";
import { useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const iconCreateLiveTask = require("../../images/iconCreateLiveTask.svg");
const iconCreateHomeTask = require("../../images/iconCreateHomeTask.svg");

const DialogQuestion = ({ onDialog, onChange, value }) => {
  const [questionType, setQuestionType] = useState("");
  const {checkUserPermission} = useHasPermission()

  if(!checkUserPermission('assessment','add')){
    return ;
  }
  return (
    <div
      role="presentation"
      className="dialog-question"
      onClick={() => onDialog(false)}
    >
      <div
        role="presentation"
        className="dialog-question__component"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-question__component__header">
          <h2 className="dialog-question__component__header__title">
            Choose type of question
          </h2>
          <button
            className="dialog-question__component__header__button"
            onClick={() => onDialog(false)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                stroke="#C1C5CB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <form
          name="live-home-task"
          onChange={onChange}
          className="form-container"
        >
          <label>
            <input
              type="checkbox"
              value="live-task"
              name="question-task"
              checked={questionType === "live-task"}
              onChange={(e) => {
                setQuestionType(e.target.value);
              }}
            />
            <div className="form-container__image">
              <img src={iconCreateLiveTask} alt="live-task-icon" />
            </div>
            <span className="form-container__title">Live Task</span>
          </label>
          <label>
            <input
              type="checkbox"
              value="take-home-task"
              name="question-task"
              checked={questionType === "take-home-task"}
              onChange={(e) => {
                setQuestionType(e.target.value);
              }}
            />
            <div className="form-container__image">
              <img src={iconCreateHomeTask} alt="home-task-icon" />
            </div>
            <span className="form-container__title">Take Home Task</span>
          </label>
        </form>
        <div className="form-container__footer">
          <div className="form-container__group">
            <button
              className="form-container__group__cancel"
              onClick={() => onDialog(false)}
            >
              Cancel
            </button>
            <Link to={`/coding-assessments${value ? "/" + value : ""}`}>
              <button
                onClick={() => onDialog(true)}
                className="btn--white-green form-container__group__next"
              >
                Next
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogQuestion;

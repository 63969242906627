import ReachEditor from "./ReachEditor";
import NavigateToSteps from "./NavigateToSteps";
import { useDispatch } from "react-redux";
import {
  setAssessmentQuestion,
  setValidStatusBar,
} from "../../store/assessmentTask/assessmentTask.actions";
import React, { useEffect, useState } from "react";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { validateFormField } from "../../config/utils";

const HomeTaskStepThree = (props) => {
  const { homeQuestion, validFieldsButtonCreate, validFields, setValidFields } =
    props;

  const [validFieldsInstruction, setValidFieldsInstruction] = useState(false);
  const [validFieldsStarterCode, setValidFieldsStarterCode] = useState(false);

  const dispatch = useDispatch();

  const html = draftToHtml(
    convertToRaw(homeQuestion.candidateInstruction.getCurrentContent())
  );

  useEffect(() => {
    dispatch(
      setValidStatusBar({
        questionId: homeQuestion.id,
        stepOne: true,
        stepTwo: true,
        stepThree: validFields,
      })
    );
  }, [dispatch, homeQuestion.id, validFields]);

  useEffect(() => {
    setValidFields(
      !(homeQuestion.starterCode === "" || html === "<p></p>\n") &&
        !(homeQuestion.starterCode === null || html === "<p></p>\n")
    );
    setValidFieldsInstruction(html === "<p></p>\n");
  }, [homeQuestion.starterCode, html, setValidFields]);

  return (
    <NavigateToSteps>
      <div className="task-home">
        <div className="task-home__container">
          {homeQuestion.isIncludeTestCases ? (
            <h3 className="task-home__container__title">
              03. Content of the question
            </h3>
          ) : (
            <h3 className="task-home__container__title">
              02. Content of the question
            </h3>
          )}
          <label htmlFor="live-task-label" className="label-task-font">
            Candidate Instruction <span className="asterisk-sign">*</span>
          </label>
          <ReachEditor
            homeQuestion={homeQuestion}
            validate={validateFormField(
              html,
              validFieldsInstruction,
              validFieldsButtonCreate
            )}
          />
          <label htmlFor="live-task-label" className="label-task-font">
            Starter code <span className="asterisk-sign">*</span>
            <p className="task-home__starter-code__note">Note: To ensure successful execution of the assessment test cases, it is mandatory to pass the parameters {'('}previously described{')'} in the function call of your constructed starter code.</p>
          </label>
          <textarea
            id="lineCounter"
            wrap="off"
            rows={4}
            className={`live-task-input-textarea ${
              validateFormField(
                homeQuestion.starterCode,
                validFieldsStarterCode,
                validFieldsButtonCreate
              ) && "error"
            }`}
            placeholder="Write the code with which the candidate will start his assessment"
            value={homeQuestion.starterCode}
            onChange={(e) => {
              dispatch(
                setAssessmentQuestion({
                  ...homeQuestion,
                  starterCode: e.target.value,
                })
              );
              setValidFieldsStarterCode(e.target.value === "");
            }}
          />
          {validateFormField(
            homeQuestion.starterCode,
            validFieldsStarterCode,
            validFieldsButtonCreate
          ) && <div className="error-data-one">Invalid data</div>}
          <div className="group-button-task-home-three">
            <div>
              <button
                onClick={() => {
                  !homeQuestion.isIncludeTestCases
                    ? dispatch(
                        setAssessmentQuestion({
                          ...homeQuestion,
                          formStep: homeQuestion.formStep - 2,
                        })
                      )
                    : dispatch(
                        setAssessmentQuestion({
                          ...homeQuestion,
                          formStep: homeQuestion.formStep - 1,
                        })
                      );
                }}
                className="back-button-task"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </NavigateToSteps>
  );
};

export default HomeTaskStepThree;

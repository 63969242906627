import React from "react";
import { CheckboxButton } from "../CheckboxButton";
const iconSearchGray = require("../../images/iconSearchGray.svg");

const TabLanguages = ({
  handleClickLanguage,
  isLanguage,
  handleCheckLanguageCheckbox,
  isCheckLanguage,
  languages,
}) => {
  return (
    <>
      <div className="search-filter-input">
        <button className="search-filter-input__search-button">
          <img
            src={iconSearchGray}
            alt="search-icon"
            className="search-filter-input__icon"
          />
        </button>
        <input
          type="search"
          placeholder="Search Languages"
          className="search-filter-input__input"
          onChange={handleClickLanguage}
          value={isLanguage ? isLanguage : []}
          onKeyDown={(e) => e.key === "Enter"}
        />
      </div>
      <div className="gradient"></div>
      <div className="language-filter-container">
        {languages.map((item, id) => (
          <div style={{ marginTop: "5px", marginBottom: "5px" }} key={id}>
            <CheckboxButton
              style={{ paddingRight: "25px" }}
              key={id}
              type="checkbox"
              name={item}
              id={id}
              handleClick={handleCheckLanguageCheckbox}
              isChecked={isCheckLanguage.includes(id.toString())}
            />
            <span style={{ paddingLeft: "15px", fontSize: "14px" }}>
              {item}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default React.memo(TabLanguages);

import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { CheckboxButton } from "../CheckboxButton";
import cn from "classnames";
import { navigate } from "@reach/router";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import useWindowSize from "../../hook/common/useWindowSize";
import { useDispatch } from "react-redux";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
const pencil = require("./../../images/pencil.svg");
const remove = require("./../../images/delete.svg");

import {
  addDatabase,
  setDescription,
  setInstruction,
  setLanguage,
  setName,
  setPackage,
  setStarterCode
} from "../../store/liveTask/liveTask.actions";
import {
  setAssessmentDescription,
  setAssessmentName,
} from "../../store/assessmentTask/assessmentTask.actions";
import { AuthGuard, ShowToolTipComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const TableRows = (props) => {
  const {
    rawData,
    info,
    isOddNumber,
    handleRemove,
    keyRow,
    handleClick,
    isCheck,
    id,
    isCheckAll,
    index,
    isMouseOver,
    isMouseOverScroll,
    cellStyles = [
      {
        color: "#099C73",
        fontWeight: 900,
        position: "sticky",
        left: 0,
        width: "280px",
      },
      { color: "#464E57", fontWeight: 400, width: "200px" },
      { color: "#343B43", fontWeight: 400, width: "200px" },
      { color: "#343B43", fontWeight: 400, width: "200px" },
      { color: "#343B43", fontWeight: 400, width: "200px" },
      { color: "#343B43", fontWeight: 400, width: "200px" },
      { color: "#099C73", fontWeight: 500, width: "200px" },
      { color: "#737980", fontWeight: 400, width: "100px" },
    ],
  } = props;

  const [widthRowsAssessment, setWidthRowsAssessment] = useState(0);

  const rowsRef = useRef(null);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const { width } = rowsRef?.current?.getBoundingClientRect();
    setWidthRowsAssessment(width);
  }, [widthRowsAssessment]);

  const [width] = useWindowSize();

  //TODO: need add fields from back-end object
  const onEditRow = (e, idx, row) => {
    return rawData.find((item) => {
      if (item.taskId === row.taskId && item.assessmentType === "take-home") {
        navigate("/coding-assessments/take-home-task/" + item.taskId);
        dispatch(setAssessmentName(item?.name));
        dispatch(setAssessmentDescription(item?.description));
      } else if (
        item.taskId === row.taskId &&
        item.assessmentType === "live-task"
      ) {
        navigate("/coding-assessments/live-task/" + item.taskId);
        dispatch(setName(item?.name));
        dispatch(setDescription(item?.description));
        dispatch(setLanguage({ id: item?.languageId, name: row?.languageId }));
        dispatch(setPackage({ id: item?.packageId, name: row?.packageId }));
        dispatch(addDatabase({ description: '', id: item.databaseId, packages: null, questionId: '', script: '', title: '' }))
        dispatch(setStarterCode(item?.starterCode));
        if (item?.instruction) {
          const parsedContent = JSON.parse(item.instruction);
          try {
            let editorState;
            if (parsedContent) {
              const blocksFromHTML = convertFromHTML(parsedContent);
              const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
              editorState = EditorState.createWithContent(contentState);
            } else {
              editorState = EditorState.createEmpty();
            }
            dispatch(setInstruction(editorState));
          } catch (error) {
            console.log("Error while storing instruction", error)
          }
        }
      }
    });
  };

  const newWidth = useMemo(() => {
    return widthRowsAssessment > width - 122;
  }, [width, widthRowsAssessment]);

  return (
    <TableRow
      ref={rowsRef}
      style={{ padding: "0 10px 0 10px" }}
      className={`table-ui__row ${isOddNumber ? "table-ui__row--white" : "table-ui__row--gray"
        }`}
    >
      {Object.entries(info).map((name: [string, any], index) => {
        const idx = name[0] === "taskId" ? name[1] : `${name[0]}-${index}`;
        const infoKeys = Object.keys(info);
        const indexName = infoKeys.indexOf("name") - 2;
        if (name[0].includes("name") && !name[0].includes("id") && !name[0].includes("taskId")) {
          return (
            <TableCell
              style={{
                position: cellStyles[indexName]?.position,
                left: cellStyles[indexName]?.left,
                minWidth: cellStyles[indexName]?.width,
                padding: "0 0 0 16px",
                zIndex: cellStyles[indexName]?.zIndex,
                margin: "0 -10px",
              }}
              key={idx}
              className={cn(
                "table-ui__row--text",
                {
                  "table-ui__row--green-text": name[0] === "name",
                },
                { "table-ui__row--white": isOddNumber === true },
                { "table-ui__row--gray": isOddNumber === false },
                {
                  "table-ui__row-shadow":
                    isMouseOver || isMouseOverScroll ? newWidth : false,
                }
              )}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AuthGuard module={'assessment'} permission={'delete'}>
                  <div style={{ marginRight: "40px" }}>
                    <CheckboxButton
                      key={info.taskId}
                      type="checkbox"
                      name={info.name}
                      id={info.taskId}
                      isCheckAll={isCheckAll}
                      handleClick={(e) => handleClick(e, info.taskId)}
                      isChecked={isCheck.includes(info.taskId.toString())}
                    />
                  </div>
                </AuthGuard>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    maxWidth: "200px",
                    fontFamily: "Avenir LT Std, sans-serif",
                    color: cellStyles[indexName]?.color,
                    fontWeight: cellStyles[indexName]?.fontWeight,
                    overflowX:"auto",
                    scrollbarWidth:"none"
                  }}
                >
                  {name[1] || name[1] === "" || name[1] === undefined ? name[1] : <span>{" "}</span>}
                </span>
              </div>
            </TableCell>
          );
        } else if (name[0] !== "taskId" && name[0] !== "id" && name[0] !== "name") {
          return (
            <TableCell
              style={{ minWidth: cellStyles[index]?.width }}
              key={idx}
              className={cn("table-ui__row--text", {
                "table-ui__row--green-text": name[0] === "name" || name[0] === "database",
              })}
            >
              {name[0].includes("status") ? (
                <div
                  className={cn("table-ui__row-status-container", {
                    "table-ui__row-status-active": name[1].includes("ACTIVE"),
                    "table-ui__row-status-draft": name[1].includes("DRAFT"),
                  })}
                >
                  {name[1] || name[1] === "" || name[1] === undefined ? (
                    name[1]
                  ) : (
                    <span> </span>
                  )}
                  {Array.isArray(name[1]) ? name[1].join(" | ") : <span> </span>}
                </div>
              ) : (
                <div
                  className="table-ui__row-title"
                  style={{
                    fontFamily: "Avenir LT Std, sans-serif",
                    color: cellStyles[index]?.color,
                    fontWeight: cellStyles[index]?.fontWeight,
                  }}
                >
                  {name[1] || name[1] === "" || name[1] === undefined ? name[1] : <span>{" "}</span>}
                </div>
              )}
            </TableCell>
          );
        }
      })}
      <AuthGuard module={'assessment'} permission={['edit', 'delete']}>
        <TableCell className="table-ui__icon-wrapper">
          <AuthGuard module={'assessment'} permission={'edit'}>
              <button
                onClick={(e) => onEditRow(e, index, keyRow)}
                className="table-ui__row-icon-pen"
              >
            <ShowToolTipComponent text='Edit details' position="bottom">
                <img src={pencil} alt="pencil-icon" />
            </ShowToolTipComponent>
              </button>
          </AuthGuard>
          <AuthGuard module={'assessment'} permission={'delete'}>
              <button
                onClick={() => handleRemove(keyRow.taskId)}
                className="table-ui__row-icon-delete"
              >
            <ShowToolTipComponent text='Delete' position="bottom">
                <img src={remove} alt="remove" />
            </ShowToolTipComponent>
              </button>
          </AuthGuard>
        </TableCell>
      </AuthGuard>
    </TableRow>
  );
};

export default React.memo(TableRows);

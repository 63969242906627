import React from "react";
import { useDispatch } from "react-redux";
import { setQuestions } from "../../store/assessmentTask/assessmentTask.actions";
import { uuidv4 } from "../../config/utils";

const addQuestionTestCase = require("../../images/addQuestionTestCase.svg");

const AddQuestionTask = ({ assessmentQuestion }) => {
  const dispatch = useDispatch();

  const addTaskQuestion = () => {
    if (setQuestions.length < 5) {
      dispatch(setQuestions([{ ...assessmentQuestion, id: uuidv4() }]));
    } else {
      return undefined;
    }
  };

  return (
    <>
      <button onClick={addTaskQuestion}>
        <img
          className="cross-add-new-question"
          src={addQuestionTestCase}
          alt="cross-question"
        />
      </button>
    </>
  );
};

export default AddQuestionTask;

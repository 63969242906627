const DeleteQuestion = require("../../images/DeleteQuestion.svg");

function DialogDeleteQuestion({ onDialogDelete, onDelete, deleteId }) {
  return (
    <div className="coding-dialog" onClick={() => onDialogDelete(false)}>
      <div
        className="coding-dialog__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="coding-dialog__head">
          <h2 className="coding-dialog__head__title">Delete Question</h2>
          <button
            className="coding-dialog__head__cross-ico"
            onClick={() => {
              onDialogDelete(false);
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                stroke="#C1C5CB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <img
          className="coding-dialog__center-img"
          src={DeleteQuestion}
          alt="cancel-icon"
        />
        <h3 className="coding-dialog__message">
          Are you sure you want to delete this question:{" "}
          <span style={{ fontWeight: 600 }}>{deleteId.message}</span>? This
          action cannot be undone
        </h3>
        <div className="coding-dialog__footer">
          <div
            className="coding-dialog__footer__container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <button
              className="coding-dialog__footer__container__cancel"
              onClick={() => onDialogDelete(false)}
            >
              Cancel
            </button>
            <button
              className="coding-dialog__footer__container__delete"
              onClick={() => {
                onDelete(deleteId.event, deleteId.id, deleteId.message);
                onDialogDelete(false);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DialogDeleteQuestion;

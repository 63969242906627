import React, { useEffect, useState } from "react";
import NavigateToSteps from "./NavigateToSteps";
import { useDispatch, useSelector } from "react-redux";
import {
  setAssessmentDescription,
  setAssessmentName,
  setFormQuestions,
} from "../../store/assessmentTask/assessmentTask.actions";
import { assessmentTaskSelectors } from "../../store/assessmentTask/assessmentTask.selectors";
import { validateFormField } from "../../config/utils";

const HomeTaskStepOne = (props) => {
  const dispatch = useDispatch();
  const { validFields, setValidFields } = props;
  const [validFieldButtonEvent, setValidFieldButtonEvent] = useState(false);
  const [validFieldsName, setValidFieldsName] = useState(false);
  const [validFieldsDescription, setValidFieldsDescription] = useState(false);

  const { assessmentName, assessmentDescription } = useSelector(
    assessmentTaskSelectors.getAllState
  );

  useEffect(() => {
    setValidFields(
      !(assessmentName === "" || assessmentDescription === "") &&
        !(assessmentName === null || assessmentDescription === null)
    );
  }, [assessmentDescription, assessmentName, setValidFields]);

  return (
    <NavigateToSteps>
      <div className="task-live">
        <form>
          <div className="task-live__container">
            <h3 className="task-live__title">
              General Information of Assessment
            </h3>
            <label className="label-task-font" htmlFor="live-task-label">
              <span>
                Name of Assessment <span className="asterisk-sign">*</span>
              </span>
            </label>
            <input
              className={`live-task-input ${
                validateFormField(
                  assessmentName,
                  validFieldsName,
                  validFieldButtonEvent
                ) && "error"
              }`}
              type="text"
              maxLength={120}
              value={assessmentName}
              placeholder="Enter question title"
              onChange={(e) => {
                dispatch(setAssessmentName(e.target.value));
                setValidFieldsName(e.target.value === "");
              }}
            />
            {validateFormField(
              assessmentName,
              validFieldsName,
              validFieldButtonEvent
            ) && <div className="error-data">Invalid data</div>}
            <label htmlFor="live-task-label" className="label-task-font">
              Assessment Description <span className="asterisk-sign">*</span>
            </label>
            <textarea
              rows={4}
              className={`live-task-input-textarea ${
                validateFormField(
                  assessmentDescription,
                  validFieldsDescription,
                  validFieldButtonEvent
                ) && "error"
              }`}
              placeholder="Write question description"
              value={assessmentDescription}
              onChange={(e) => {
                dispatch(setAssessmentDescription(e.target.value));
                setValidFieldsDescription(e.target.value === "");
              }}
            />
            {validateFormField(
              assessmentDescription,
              validFieldsDescription,
              validFieldButtonEvent
            ) && <div className="error-data">Invalid data</div>}
            <div style={{ fontSize: "12px", marginTop: "24px", color: "#999EA5" }}>
              This description will be visible only in list of coding questions
            </div>
            <div className="first-group-button-task">
              <div>
                <button
                  onClick={(e) => {
                    validFields && dispatch(setFormQuestions(true));
                    setValidFieldButtonEvent(true);
                    e.preventDefault();
                  }}
                  type="submit"
                  className="button-save"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </NavigateToSteps>
  );
};

export default HomeTaskStepOne;

export const updateQueryStringParameter = (uri, key, value) => {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`);
  }
  return `${uri + separator + key}=${value}`;
};

const buildSearchProductUrl = (requestConfig, url) => {
  Object.keys(requestConfig).forEach((key) => {
    if (typeof requestConfig[key] === "string" && requestConfig[key] !== "") {
      url = updateQueryStringParameter(
        url,
        key,
        encodeURIComponent(requestConfig[key])
      );
    } else if (
      typeof requestConfig[key] === "number" ||
      (typeof requestConfig[key] === "object" &&
        requestConfig[key] !== null &&
        requestConfig[key].length > 0)
    ) {
      url = updateQueryStringParameter(
        url,
        key,
        encodeURIComponent(JSON.stringify(requestConfig[key]))
      );
    } else if (
      typeof requestConfig[key] === "boolean" &&
      requestConfig[key] !== false
    ) {
      url = updateQueryStringParameter(
        url,
        key,
        encodeURIComponent(requestConfig[key])
      );
    }
  });
  return url;
};

export const requestSearchUrl = (url, requestConfig) => {
  const token: string = localStorage.getItem("token");
  const requestUrl = buildSearchProductUrl(requestConfig, url);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return requestUrl;
};

export const uuidv4 = () => {
  const crypto = window.crypto;
  // @ts-ignore
  // eslint-disable-next-line
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const updateAssessmentTasks = (
  items: any,
  itemId: any,
  objId: any,
  newObjProps: any
) => {
  return items.map((item: any) => {
    if (item[objId] === itemId) {
      return {...item, ...newObjProps};
    }
    return item;
  });
};

export const validateFormField = (value, validState, event) => {
  return (
    (value === "" || event) &&
    (value === null ||
      value === "<p></p>\n" ||
      value === "Untitled question" ||
      ((value === "" || event) && validState))
  );
};

export const formatAMPM = (date) => {
  let month = date.getMonth();
  let day = date.getDate().toString().padStart(2, "0");
  let year = date.getFullYear().toString().substr(-2);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  day = day < 10 ? day : day;
  month = (month + 1).toString().padStart(2, "0");
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = month + "." + day + "." + year + " " + hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const LanguagesSet = 
[
  {
      "id": 45,
      "name": "Assembly (NASM 2.14.02)"
  },
  {
      "id": 46,
      "name": "Bash (5.0.0)"
  },
  {
      "id": 47,
      "name": "Basic (FBC 1.07.1)"
  },
  {
      "id": 75,
      "name": "C (Clang 7.0.1)"
  },
  {
      "id": 76,
      "name": "C++ (Clang 7.0.1)"
  },
  {
      "id": 48,
      "name": "C (GCC 7.4.0)"
  },
  {
      "id": 52,
      "name": "C++ (GCC 7.4.0)"
  },
  {
      "id": 49,
      "name": "C (GCC 8.3.0)"
  },
  {
      "id": 53,
      "name": "C++ (GCC 8.3.0)"
  },
  {
      "id": 50,
      "name": "C (GCC 9.2.0)"
  },
  {
      "id": 54,
      "name": "C++ (GCC 9.2.0)"
  },
  {
      "id": 86,
      "name": "Clojure (1.10.1)"
  },
  {
      "id": 51,
      "name": "C# (Mono 6.6.0.161)"
  },
  {
      "id": 77,
      "name": "COBOL (GnuCOBOL 2.2)"
  },
  {
      "id": 55,
      "name": "Common Lisp (SBCL 2.0.0)"
  },
  {
      "id": 90,
      "name": "Dart (2.19.2)"
  },
  {
      "id": 56,
      "name": "D (DMD 2.089.1)"
  },
  {
      "id": 57,
      "name": "Elixir (1.9.4)"
  },
  {
      "id": 58,
      "name": "Erlang (OTP 22.2)"
  },
  {
      "id": 44,
      "name": "Executable"
  },
  {
      "id": 87,
      "name": "F# (.NET Core SDK 3.1.202)"
  },
  {
      "id": 59,
      "name": "Fortran (GFortran 9.2.0)"
  },
  {
      "id": 60,
      "name": "Go (1.13.5)"
  },
  {
      "id": 95,
      "name": "Go (1.18.5)"
  },
  {
      "id": 88,
      "name": "Groovy (3.0.3)"
  },
  {
      "id": 61,
      "name": "Haskell (GHC 8.8.1)"
  },
  {
      "id": 91,
      "name": "Java (JDK 17.0.6)"
  },
  {
      "id": 62,
      "name": "Java (OpenJDK 13.0.1)"
  },
  {
      "id": 63,
      "name": "JavaScript (Node.js 12.14.0)"
  },
  {
      "id": 93,
      "name": "JavaScript (Node.js 18.15.0)"
  },
  {
      "id": 78,
      "name": "Kotlin (1.3.70)"
  },
  {
      "id": 64,
      "name": "Lua (5.3.5)"
  },
  {
      "id": 89,
      "name": "Multi-file program"
  },
  {
      "id": 79,
      "name": "Objective-C (Clang 7.0.1)"
  },
  {
      "id": 65,
      "name": "OCaml (4.09.0)"
  },
  {
      "id": 66,
      "name": "Octave (5.1.0)"
  },
  {
      "id": 67,
      "name": "Pascal (FPC 3.0.4)"
  },
  {
      "id": 85,
      "name": "Perl (5.28.1)"
  },
  {
      "id": 68,
      "name": "PHP (7.4.1)"
  },
  {
      "id": 43,
      "name": "Plain Text"
  },
  {
      "id": 69,
      "name": "Prolog (GNU Prolog 1.4.5)"
  },
  {
      "id": 70,
      "name": "Python (2.7.17)"
  },
  {
      "id": 92,
      "name": "Python (3.11.2)"
  },
  {
      "id": 71,
      "name": "Python (3.8.1)"
  },
  {
      "id": 80,
      "name": "R (4.0.0)"
  },
  {
      "id": 72,
      "name": "Ruby (2.7.0)"
  },
  {
      "id": 73,
      "name": "Rust (1.40.0)"
  },
  {
      "id": 81,
      "name": "Scala (2.13.2)"
  },
  {
      "id": 82,
      "name": "SQL (SQLite 3.27.2)"
  },
  {
      "id": 83,
      "name": "Swift (5.2.3)"
  },
  {
      "id": 74,
      "name": "TypeScript (3.7.4)"
  },
  {
      "id": 94,
      "name": "TypeScript (5.0.3)"
  },
  {
      "id": 84,
      "name": "Visual Basic.Net (vbnc 0.0.0.5943)"
  }
]


export const packageSelect = [
  { id: 1, name: "JDBC (Postgress)" },
  { id: 2, name: "JDBC (My SQL)" },
];

import React, { useState } from "react";
import { DataTypes } from "../types/assessmentTask";
const addQuestionTestCase = require("../images/addQuestionTestCase.svg");
import { useDispatch } from "react-redux";
import { addHomeTaskTestCaseInputs } from "../store/assessmentTask/assessmentTask.actions";
const removeIcon = require("../images/icon/remove.svg");

const ArrayInputs = ({ inputIdentifier, inputName, inputType, inputValue }) => {
  const elements = inputValue?.name || [];
  const dispatch = useDispatch();
  const arrayElementType =
    inputType === DataTypes.ARRAY_INTEGER ? "number" : "text";

  const updateStoreState = (updatedInputValue) => {
    dispatch(
      addHomeTaskTestCaseInputs({
        id: inputIdentifier.id,
        index: inputIdentifier.index,
        name: updatedInputValue,
        questionId: inputIdentifier.questionId,
      })
    );
  };

  const addElement = (e) => {
    e.preventDefault();
    const updatedInputValue = [
      ...elements,
      {
        id: Math.floor(Math.random() * 1000000),
        lable: `${inputName}_e${elements.length + 1}`,
        value: null,
      },
    ];
    updateStoreState(updatedInputValue);
  };

  const addValue = (e, targetElement) => {
    const updatedInputValue = elements.map((el) => {
      if (el.id === targetElement.id) {
        return { ...el, value: e.target.value };
      }
      return el;
    });
    updateStoreState(updatedInputValue);
  };

  const removeElement = (targetElement) => {
    const  updatedInputValue = elements.filter(
      (el) => el.id !== targetElement.id
    ).map((el,index)=>({
      ...el,
      lable: `${inputName}_e${index+1}`
    }));
    updateStoreState(updatedInputValue);
  };

  return (
    <>
      <div className="array-inputs__wrapper">
        {elements.map((el, index) => (
          <div key={el?.id} className="array-inputs__element">
            <label
              htmlFor="array-inputs"
              className="array-inputs__element__lable"
            >
              <span>{el?.lable}</span>
              {elements.length > 1 && (
                <img
                  src={removeIcon}
                  alt="remove"
                  onClick={() => removeElement(el)}
                />
              )}
            </label>

            <input
              className="array-inputs__element__value"
              type={arrayElementType}
              placeholder="value"
              value={el?.value}
              onChange={(e) => addValue(e, el)}
            />
          </div>
        ))}
      </div>
      <div>
        <button onClick={(e) => addElement(e)}>
          <img
            className="cross-add-new-input-case"
            src={addQuestionTestCase}
            alt="add-input-task"
          />
          <span className="cross-add-new-input-text">Add element</span>
        </button>
      </div>
    </>
  );
};

export default ArrayInputs;

import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

export const Portal = (props) => {
  const el = useRef(document.createElement("div"));
  const portal = document.getElementById("portal");

  useEffect(() => {
    portal.appendChild(el.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      portal.removeChild(el.current);
    };
  }, [portal, props.children]);

  return ReactDOM.createPortal(props.children, el.current);
};

export const PortalDiv = () => <div key="portal-001" id="portal"></div>;

import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const iconCalendar = require("../../images/iconCalendar.svg");

const TabCreatedOn = ({
  issuedOnDate,
  datePickerIsOpen,
  openDatePicker,
  setIssuedOnDate,
  monthNameSt,
}) => {
  return (
    <>
      <div style={{ height: "36px" }} className="search-filter-input">
        <div className="datepicker-container">
          <DatePicker
            closeOnScroll={true}
            showYearDropdown={true}
            scrollableYearDropdown={true}
            yearDropdownItemNumber={50}
            selected={issuedOnDate}
            open={datePickerIsOpen}
            onClickOutside={openDatePicker}
            onChange={(date: any) => {
              setIssuedOnDate(date);
            }}
            dateFormat="MMMM d, yyyy"
            placeholderText={
              monthNameSt +
              " " +
              new Date().getDate() +
              ", " +
              new Date().getFullYear()
            }
          />
        </div>
        <button
          onClick={openDatePicker}
          className="search-filter-input__search-button"
        >
          <img
            src={iconCalendar}
            alt="search-icon"
            className="search-filter-input__icon"
          />
        </button>
      </div>
    </>
  );
};

export default TabCreatedOn

import React, { useEffect, useState } from "react";
import ReachEditor from "./ReachEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  setNextStep,
  setStarterCode,
  SetValidStatusBar,
} from "../../store/liveTask/liveTask.actions";
import { liveTaskSelectors } from "../../store/liveTask/liveTask.selectors";
import NavigateToSteps from "./NavigateToSteps";
import { validateFormField } from "../../config/utils";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

const LiveTaskNextStep = (props) => {
  const { validFieldsButtonCreate } = props;
  const [validFields, setValidFields] = useState(false);
  const [validFieldsInstruction, setValidFieldsInstruction] = useState(false);
  const [validFieldsStarterCode, setValidFieldsStarterCode] = useState(false);

  const dispatch = useDispatch();

  const { starterCode, nextStep, instruction } = useSelector(
    liveTaskSelectors.getAllState
  );
  const html = draftToHtml(convertToRaw(instruction.getCurrentContent()));

  useEffect(() => {
    setValidFields(
      !(starterCode === "" || html === "<p></p>\n") &&
        !(starterCode === null || html === "<p></p>\n")
    );
    setValidFieldsInstruction(html === "<p></p>\n");
  }, [starterCode, html]);

  useEffect(() => {
    dispatch(SetValidStatusBar({ stepOne: true, stepTwo: validFields }));
  }, [dispatch, validFields]);

  return (
    <NavigateToSteps>
      <div className="task-live">
        <div className="task-live__container">
          <h3 className="task-live__title">02. Content of the question</h3>
          <label htmlFor="live-task-label" className="label-task-font">
            Candidate Instruction <span className="asterisk-sign">*</span>
          </label>
          <ReachEditor
            validate={validateFormField(
              html,
              validFieldsInstruction,
              validFieldsButtonCreate
            )}
          />
          <label htmlFor="live-task-label" className="label-task-font">
            Starter code <span className="asterisk-sign">*</span>
          </label>
          <textarea
            id="lineCounter"
            wrap="off"
            rows={4}
            value={starterCode}
            className={`live-task-input-textarea ${
              validateFormField(
                starterCode,
                validFieldsStarterCode,
                validFieldsButtonCreate
              ) && "error"
            }`}
            placeholder="Write the code with which the candidate will start his assessment"
            onChange={(e) => {
              dispatch(setStarterCode(e.target.value));
              setValidFieldsStarterCode(e.target.value === "");
            }}
          />
          {validateFormField(
            starterCode,
            validFieldsStarterCode,
            validFieldsButtonCreate
          ) && <div className="error-data-one">Invalid data</div>}
          <p id="live-task-input__wrap"></p>
          <div className="group-button-task">
            <div>
              <button
                onClick={() => dispatch(setNextStep(nextStep - 1))}
                className="back-button-task"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </NavigateToSteps>
  );
};

export default LiveTaskNextStep;
